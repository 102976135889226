.nav {
    display: flex;
    padding: 0.5rem;
    background-color: rgb(4, 4, 49);
    color: white;
    font-size: 1.1rem;
    position: relative;

    .logo {
        display: flex;
        width: 33%;
        padding: 0.8rem;

        #logo-image {
            width: 50px;
            position: absolute;
            top: 15%;
            left: 1%;
        }

        a {
            text-decoration: none;
            color: white;

            span {
                margin-left: 52px;
                letter-spacing: 0.15rem;
                word-spacing: 0.3rem;
            }
        }
    }

    .others {
        width: calc(100% - 33%);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        letter-spacing: 0.1rem;

        ul {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;

            li {

                a {
                    color: white;
                    text-align: center;
                    text-decoration: none;
                    padding: 0.3rem;
                    margin: 0 1rem;
                    border-radius: 5px;

                    &:hover {
                        border-bottom: 1px solid white;
                    }
                }

                .nav-dropdown {
                    overflow: hidden;
                    cursor: pointer;
                    border-radius: 5px;
                    padding: 0.3rem;
                    margin: 0 1rem;


                    &:hover {
                        border-bottom: 1px solid white;
                    }

                    .nav-dropbtn {
                        font-size: 1.1rem;
                        color: white;
                        border-radius: 5px;
                        border: none;
                        outline: none;
                        background-color: inherit;
                        font-family: inherit;
                        cursor: pointer;

                        .arrow {
                            margin-left: 3px;
                            font-size: 15px;
                        }
                    }

                    .nav-dropdown-content {
                        display: block;
                        position: absolute;
                        top: 100%;
                        right: 15%;
                        background-color: #f9f9f9;
                        width: 250px;
                        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                        z-index: 1;


                        button {
                            font-size: 1.1rem;
                            outline: none;
                            width: 100%;
                            border: none;
                            display: block;
                            text-align: center;
                            cursor: pointer;
                            font-family: inherit;
                            padding: 0.5rem;

                            a {
                                margin: 0;
                                color: black;
                                text-decoration: none;

                                &:hover {
                                    border-bottom: none;
                                }
                            }

                            &:hover {
                                background-color: #ddd;
                            }
                        }
                    }
                }
            }
        }
    }

    .others-display-list {
        ul {
            display: none;
        }
    }

    .nav-bars {
        display: none;
    }
}

@media only screen and (max-width: 1140px) {
    .nav {
        font-size: 1rem;

        .logo {
            width: 50%;

            #logo-image {
                left: 0.5%;
            }

            a {
                span {
                    margin-left: 30px;
                    word-spacing: 0.2rem;
                }
            }
        }

        .others {
            padding-left: 0%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            ul {
                li {

                    a {
                        margin: 0 0.3rem;
                        padding: 0.1rem;
                    }

                    .nav-dropdown {
                        padding: 0.1rem;
                        margin: 0 0.3rem;

                        .nav-dropbtn {
                            font-size: 1rem;

                            .arrow {
                                margin-left: 1px;
                                font-size: 11px;
                            }
                        }

                        .nav-dropdown-content {
                            font-size: 1rem;
                            right: 5%;
                            width: 210px;

                            button {
                                padding: 0.2rem 0.5rem;
                            }
                        }
                    }
                }
            }
        }

        .others-display-list {
            ul {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .nav {
        font-size: 1rem;

        .logo {
            width: 100%;
        }

        .others {
            display: none;
        }

        .others-display-list {

            ul {
                list-style: none;
                display: inline;
                background-color: rgb(4, 4, 49);
                position: absolute;
                top: 100%;
                right: 0%;
                z-index: 1;
                border-top-right-radius: 0;
                cursor: pointer;

                li {
                    margin: 0;
                    
                    
                    button {
                        padding: 0.2rem 0.5rem;
                        font-size: 1rem;
                        border: none;
                        outline: none;
                        text-align: center;
                        background-color: rgb(4, 4, 49);
                        width: 100%;
                        display: block;

                        a {
                            color: whitesmoke;
                            text-decoration: none;

                            &:hover {
                                border-bottom: none;
                            }
                        }

                        &:hover {
                            background-color: rgb(8, 8, 86);
                        }
                    }

                    .nav-dropdown {
                        margin: 0;

                        &:hover {
                            border-bottom: none;
                        }

                        .nav-dropbtn {
                            font-size: 1rem;
                            color: whitesmoke;
                            padding: 0.1rem 0.3rem;
                            border: none;
                            outline: none;
                            background-color: inherit;
                            padding: 0;
                            cursor: pointer;

                            .arrow {
                                font-size: 11px;
                            }
                        }

                        .nav-dropdown-content {
                            display: block;
                            position: absolute;
                            top: 100%;
                            right: 0%;
                            width: 200px;
                            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                            z-index: 1;

                            button {
                                width: 100%;
                                background-color: #f9f9f9;
                                font-size: 1.1rem;
                                cursor: pointer;
                                display: block;
                                text-align: center;
                                padding: 0.2rem;
                                outline: none;
                                border: none;

                                a {
                                    color: black;
                                    text-decoration: none;
                                    margin: 0;

                                }

                                &:hover {
                                    background-color: #ddd;
                                }
                            }
                        }
                    }
                }
            }
        }

        .nav-bars {
            width: 10%;
            display: block;
            position: absolute;
            right: 2%;
            top: 35%;
            font-size: 20px;
            cursor: pointer;
            background-color: inherit;
            color: white;
            border: none;
            outline: none;
        }
    }
}


@media only screen and (max-width: 562px) {
    .nav {
        font-size: 0.9rem;

        .logo {
            width: 600%;
            padding: 0.8rem 0.5rem;

            #logo-image {
                top: 13%;
                width: 45px;
            }
        }

        .others-display-list {

            ul {

                li {
                    padding: 0.1rem;

                    button {
                        font-size: 0.9rem;
                    }

                    .nav-dropdown {

                        .nav-dropbtn {
                            font-size: 0.9rem;
                            padding: 0;
                        }

                        .nav-dropdown-content {
                            width: 100px;
                            button{
                                font-size: 0.9rem;

                            a {
                                font-size: 0.8rem;
                            }
                        }
                        }
                    }
                }
            }
        }
    }
}