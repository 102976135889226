@import url('https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Aref+Ruqaa+Ink:wght@700&family=Lato:ital,wght@0,400;1,100;1,300&family=Lobster+Two&family=Montserrat:wght@400;500&family=Pathway+Extreme:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap');

@keyframes slidy {
    0% {
        left: 0%;
    }

    20% {
        left: 0%;
    }

    25% {
        left: -100%;
    }

    45% {
        left: -100%;
    }

    50% {
        left: -200%;
    }

    70% {
        left: -200%;
    }

    75% {
        left: -300%;
    }

    95% {
        left: -300%;
    }

    100% {
        left: -400%;
    }
}

div#slider {
    overflow: hidden;

    figure {
        position: relative;
        width: 500%;
        margin: 0;
        left: 0;
        text-align: left;
        font-size: 0;
        animation: 10s slidy infinite;
        opacity: 0.7;

        img {
            width: 20%;
            float: left;
            height: 100vh;
        }
    }

    .text {
        position: absolute;
        top: 50%;
        text-align: center;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 3rem;
        color: rgb(24, 24, 24);
        font-family: 'Lato', sans-serif;
        font-weight: 700;
    }

    .small-text {
        position: absolute;
        top: 65%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        font-family: 'Lato', sans-serif;
        font-style: italic;
        font-size: 1.5rem;
        font-weight: 700;
        color: rgb(62, 61, 61);
    }

    .btn {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.3rem;
        letter-spacing: 0.1rem;
        padding: 0.6% 1%;
        border-radius: 5px;
        background-color: rgb(4, 4, 49);
        color: white;
        box-shadow: 2px 2px 10px 5px grey;
        cursor: pointer;

        &:hover {
            background-color: rgb(8, 8, 92);
        }
    }
}

.info {
    font-size: 2.5rem;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    text-align: center;
    margin-top: 40px;
    color: #042d3e;

    #we {
        color: #0a5260;
        text-decoration: underline;
    }
}

.home-cards {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-card {
    width: 25%;
    margin-top: 50px;
    margin-left: 3%;
    margin-right: 3%;
    height: 47vh;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    box-shadow: 3px 3px 10px 5px grey;

    &:hover {
        transform: scale(1.05);
    }

    img {
        opacity: 0.7;
        height: 100%;
        width: 100%;
    }
}

.home-card-head {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    font-weight: 700;
    color: #0C2D48;

    span {
        color: #650954;
    }
}

.home-card-info {
    position: absolute;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 1.1rem;
        width: 80%;
        text-align: center;
        letter-spacing: 0.08rem;
    }
}

.home-card:hover .home-card-info {
    height: 47vh;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    visibility: visible;
}

.home-card:hover .home-card-head {
    display: none;
}

.display {
    margin: 4rem 0 0 0;
    background-color: rgb(243, 243, 243);

    .dis {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4rem;
        padding-top: 3rem;

        .disp {
            width: 60%;
            padding: 1rem;
            text-align: center;
            border-top: 1px solid #90ADC6;
            border-bottom: 1px solid #90ADC6;

            .display-head {
                font-size: 2.5rem;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                margin-bottom: 0.4rem;
                color: #0C1446;
            }

            .display-subhead {
                color: #1f5a61;
                font-size: 1.2rem;
                font-family: 'Poppins', sans-serif;
                font-style: italic;
                font-weight: 400;
                text-align: center;
            }
        }
    }

    .galleries {
        margin-left: 4rem;
        padding-bottom: 2rem;
    }

    .gallery-1 {
        position: relative;
        display: inline;
        cursor: pointer;

        #living-room {
            width: 45%;
            border-radius: 10px;
            height: 42.5vh;

            &:hover {
                transform: scale(1.01);
            }
        }

        #living-head {
            position: absolute;
            bottom: 40%;
            left: 1%;
            font-size: 1.1rem;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border: 0.2px solid rgba(55, 54, 54, 0.5);
            padding: 2px 10px;
            border-radius: 10px;
            background-color: rgba(55, 54, 54, 0.7);
            color: whitesmoke;
        }
    }

    .gallery-2 {
        position: relative;
        display: inline;
        cursor: pointer;

        #master-bedroom {
            width: 25%;
            height: 42.5vh;
            border-radius: 10px;
            margin-left: 10px;

            &:hover {
                transform: scale(1.01);
            }
        }

        #master-head {
            position: absolute;
            bottom: 30%;
            left: 4%;
            font-size: 1.1rem;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border: 0.2px solid rgba(55, 54, 54, 0.5);
            padding: 2px 10px;
            border-radius: 10px;
            background-color: rgba(55, 54, 54, 0.7);
            color: whitesmoke;
        }
    }

    .gallery-3 {
        position: relative;
        display: inline;
        cursor: pointer;

        #balcony {
            width: 23%;
            height: 42.5vh;
            border-radius: 10px;
            margin-left: 10px;

            &:hover {
                transform: scale(1.01);
            }
        }

        #balcony-head {
            position: absolute;
            bottom: 30%;
            left: 4%;
            font-size: 1.1rem;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border: 0.2px solid rgba(55, 54, 54, 0.5);
            padding: 2px 10px;
            border-radius: 10px;
            background-color: rgba(55, 54, 54, 0.7);
            color: whitesmoke;
        }
    }

    .gallery-4 {
        position: relative;
        display: inline;
        cursor: pointer;

        #bathroom {
            width: 25%;
            height: 42.5vh;
            border-radius: 10px;
            margin-left: 2px;
            margin-top: 8px;

            &:hover {
                transform: scale(1.01);
            }
        }

        #bathroom-head {
            position: absolute;
            bottom: 30%;
            left: 3%;
            font-size: 1.1rem;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border: 0.2px solid rgba(55, 54, 54, 0.5);
            padding: 2px 10px;
            border-radius: 10px;
            background-color: rgba(55, 54, 54, 0.7);
            color: whitesmoke;
        }
    }

    .gallery-5 {
        position: relative;
        display: inline;
        cursor: pointer;

        #kitchen {
            width: 42%;
            height: 42.5vh;
            border-radius: 10px;
            margin-left: 10px;
            margin-top: 8px;

            &:hover {
                transform: scale(1.01);
            }
        }

        #kitchen-head {
            position: absolute;
            bottom: 40%;
            left: 3%;
            font-size: 1.1rem;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border: 0.2px solid rgba(55, 54, 54, 0.5);
            padding: 2px 10px;
            border-radius: 10px;
            background-color: rgba(55, 54, 54, 0.7);
            color: whitesmoke;
        }
    }

    .gallery-6 {
        position: relative;
        display: inline;
        cursor: pointer;

        #wardrobe {
            width: 25.8%;
            height: 42.5vh;
            border-radius: 10px;
            margin-left: 10px;
            margin-top: 8px;

            &:hover {
                transform: scale(1.01);
            }
        }

        #wardrobe-head {
            position: absolute;
            bottom: 40%;
            left: 4%;
            font-size: 1.1rem;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border: 0.2px solid rgba(55, 54, 54, 0.5);
            padding: 2px 10px;
            border-radius: 10px;
            background-color: rgba(55, 54, 54, 0.7);
            color: whitesmoke;
        }
    }
}

.choice-container {
    margin-top: 3rem;
    font-size: 2rem;
    text-align: center;

    .choice-head {
        padding: 20px;
        font-weight: 700;
        color: #050A30;
        border-top: 1px solid #90ADC6;
        border-bottom: 2px solid #90ADC6;
        display: inline;
    }

    .home-container {
        position: relative;
        margin-top: 5%;
        padding-bottom: 20%;
        padding-top: 10%;
    }

    .circular-image {
        width: 30vw;
        height: 60vh;
        border-radius: 50%;
        overflow: hidden;
        background-color: #EEEDE7;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        box-shadow: 1px 1px 10px 5px rgb(173, 172, 172);
        cursor: pointer;
    }

    .circular-image img {
        width: 100%;
        height: 100%;
    }

    .rotate-image {
        width: 6vw;
        height: 12vh;
        border-radius: 50%;
        overflow: hidden;
        background-color: #EEEDE7;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        left: 32%;
        top: 28%;
        animation: circle 10s infinite linear;
    }

    .light-background {
        width: 18vw;
        position: absolute;
        text-align: center;
        font-family: 'Lato', sans-serif;
        background-color: #E9EAEC;
        padding: 10px 20px;
        border-radius: 10px;
        box-shadow: 5px 5px 10px 5px rgb(184, 184, 184);

        h3 {
            margin-bottom: 5px;
            font-size: 1.2rem;
            border-bottom: 1px solid #90ADC6;
            padding-bottom: 5px;
        }

        p {
            text-align: left;
            color: #4e5f6d;
            font-size: 1rem;
        }

    }

    .home-interior {
        position: absolute;
        top: 0;
        left: 10%;
    }

    .dark-background {
        width: 18vw;
        position: absolute;
        text-align: center;
        font-family: 'Lato', sans-serif;
        background-color: #E9EAEC;
        background-color: #3a4753;
        padding: 10px 20px;
        border-radius: 10px;
        box-shadow: 5px 5px 10px 5px rgb(184, 184, 184);

        h3 {
            margin-bottom: 5px;
            font-size: 1.2rem;
            border-bottom: 1px solid #90ADC6;
            padding-bottom: 5px;
            color: #E9EAEC;
        }

        p {
            text-align: left;
            color: #F8EFE4;
            font-size: 1rem;
        }

    }

    .home-affordable {
        position: absolute;
        top: 35%;
        left: 2%;
    }

    .home-guaranteed {
        position: absolute;
        top: 62%;
        left: 10%;
    }

    .home-number {
        position: absolute;
        top: 0%;
        right: 10%;
    }

    .home-consultation {
        position: absolute;
        top: 35%;
        right: 2%;
    }

    .home-support {
        position: absolute;
        top: 64%;
        right: 10%;
    }
}

@keyframes circle {
    0% {
        top: 28%;
        left: 32%;
    }

    50% {
        top: 50%;
        left: 32.5%;
    }

    100% {
        top: 25%;
        left: 32%;
    }
}

.home-typewriter {
    position: relative;
    height: 100px;
    width: 100%;
    margin-top: 50px;
    background-color: #2F5061;

    .home-best {
        padding: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.9rem;
        font-family: 'Red Hat Display';
        font-weight: 700;
        color: #ECFDF1;
        width: 100%;

        span {
            font-size: 1.9rem;
            font-family: 'Red Hat Display';
            font-weight: 700;
        }
    }
}

.home-footer {
    background: url(../../images/footer.jpg);
    background-size: 100% 100%;
    color: black;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    display: flex;

    .home-footer-info {
        width: 50%;
        padding: 5% 3% 5% 20%;

        .home-logo-image {
            text-align: center;
            margin-bottom: 20px;

            img {
                width: 100px;
            }
        }

        p {
            padding: 0 15px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
        }
    }

    .home-services {
        width: 15%;
        padding: 6% 1% 5% 1%;

        .home-services-head {
            text-align: center;
            margin-bottom: 40px;
            font-size: 1.8rem;
            font-family: 'Poppins', sans-serif;
            color: #1b2d4b;
        }

        ul {
            list-style: none;

            li {
                text-align: center;
                margin-bottom: 5px;

                a {
                    text-decoration: none;
                    color: black;
                    font-weight: 500;
                    font-family: 'Poppins', sans-serif;

                    &:hover {
                        color: #044662;
                    }
                }
            }
        }
    }

    .home-contact-us {
        width: 30%;
        padding: 6% 0% 5% 2%;

        .home-contact-head {
            text-align: center;
            margin-bottom: 40px;
            font-size: 1.8rem;
            font-family: 'Poppins', sans-serif;
            color: #1b2d4b;
        }

        .home-contacts {
            display: flex;
            margin-bottom: 20px;
            margin-left: 20px;

            .home-address {
                width: 50px;
                margin-right: 15px;
                margin-top: 5px;
            }

            .home-phone {
                margin-right: 15px;
                margin-top: 5px;
            }

            .home-email{
                font-size: 1.3rem;
                margin-right: 15px;
                margin-top: 5px;
            }

            p {
                font-size: 1.1rem;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
            }
        }
    }
}

@media only screen and (max-width: 1230px) {
    .choice-container {
        .circular-image {
            width: 35vw;
            height: 55vh;
        }

        .rotate-image {
            width: 7vw;
            height: 11vh;
            top: 30%;
            left: 30%;
            animation: circle1230 10s infinite linear;
        }

        .light-background {
            width: 25vw;
        }

        .dark-background {
            width: 25vw;
        }

        .home-interior {
            left: 5%;
        }

        .home-affordable {
            top: 40%;
        }

        .home-guaranteed {
            left: 5%;
            top: 67%;
        }

        .home-number {
            right: 5%;
        }

        .home-consultation {
            top: 40%;
        }

        .home-support {
            right: 5%;
            top: 74%;
        }
    }

    @keyframes circle1230 {
        0% {
            top: 30%;
            left: 30%;
        }

        50% {
            top: 50%;
            left: 30.5%;
        }

        100% {
            top: 30%;
            left: 30%;
        }
    }
}

@media only screen and (max-width: 1140px) {
    div#slider {

        .text {
            font-size: 2.5rem;
            width: 60%;
        }

        .small-text {
            width: 60%;
        }
    }

    .info {
        margin-top: 30px;
        font-size: 2.2rem;
    }

    .home-card {
        width: 30%;
        height: 37vh;
        margin-top: 30px;
        margin-left: 2%;
        margin-right: 2%;
    }

    .home-card-head {
        font-size: 1.25rem;
    }

    .home-card-info {

        p {
            width: 80%;
            margin-top: 25%;
        }
    }

    .display {
        margin-top: 3rem;

        .dis {
            margin-bottom: 3rem;

            .disp {
                width: 75%;

                .display-head {
                    font-size: 2rem;
                }
            }
        }

        .galleries {
            margin-left: 2rem;
        }

        .gallery-1 {

            #living-room {
                width: 60%;
                height: 38vh;
            }
        }

        .gallery-2 {

            #master-bedroom {
                width: 35%;
                height: 38vh;
            }
        }

        .gallery-3 {

            #balcony {
                width: 40%;
                height: 38vh;
                margin-left: 0;
            }
        }

        .gallery-4 {

            #bathroom {
                width: 55%;
                height: 38vh;
                margin-left: 10px;
            }
        }

        .gallery-5 {

            #kitchen {
                width: 45%;
                height: 38vh;
                margin-left: 0;
            }
        }

        .gallery-6 {

            #wardrobe {
                width: 50%;
                height: 38vh;
            }
        }
    }

    .home-typewriter {
        height: auto;
        margin-top: 10%;

        .home-best {
            padding: 20px 0;
            font-size: 1.3rem;

            span {
                font-size: 1.3rem;
            }
        }
    }


    .home-footer {
        font-size: 1.1rem;

        .home-footer-info {
            width: 40%;
            padding: 5% 1%;

            .home-logo-image {
                margin-bottom: 10px;
            }

            p {
                padding: 0 5px;
            }
        }

        .home-services {
            width: 30%;
            padding: 8% 1%;

            .home-services-head {
                font-size: 1.5rem;
            }
        }

        .home-contact-us {
            width: 30%;
            padding: 8% 1% 8% 0%;

            .home-contact-head {
                font-size: 1.5rem;
            }

            .home-contacts {
                margin-left: 5px;

                .home-email{
                    font-size: 1.2rem;
                }

                p {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 940px) {
    .choice-container {

        .home-container {
            padding-top: 25%;
            padding-bottom: 32%;
        }

        .circular-image {
            width: 40vw;
            height: 50vh;
        }

        .rotate-image {
            width: 8vw;
            height: 10vh;
            top: 40%;
            left: 27%;
            animation: circle940 10s infinite linear;
        }

        .light-background {
            width: 27vw;
            box-shadow: 1px 1px 10px 5px rgb(184, 184, 184);

        }

        .dark-background {
            width: 27vw;
            box-shadow: 1px 1px 5px 1px rgb(184, 184, 184);
        }
    }

    @keyframes circle940 {
        0% {
            top: 40%;
            left: 27%;
        }

        50% {
            top: 60%;
            left: 27.5%;
        }

        100% {
            top: 40%;
            left: 27%;
        }
    }
}

@media only screen and (max-width: 768px) {
    div#slider {

        figure {

            img {
                height: 80vh;
            }
        }

        .text {
            font-size: 2rem;
            width: 70%;
            top: 40%;
        }

        .small-text {
            width: 70%;
            top: 55%;
        }

        .btn {
            top: 70%;
        }
    }

    .info {
        font-size: 2rem;
    }

    .home-cards {
        display: block;
        text-align: center;
    }

    .home-card {
        width: 75%;
        text-align: center;
        margin-top: 6%;
        margin-left: 12%;
    }

    .home-card-info {

        p {
            margin-top: 20%;
        }
    }

    .display {
        margin-top: 2.5rem;

        .dis {
            margin-bottom: 2.5rem;

            .disp {
                width: 80%;

                .display-subhead {
                    font-size: 1rem;
                }
            }
        }
    }

    .choice-container {

        .home-container {
            margin-top: 7%;
            padding-top: 50%;
            padding-bottom: 50%;
            text-align: end;
        }

        .circular-image {
            width: 50vw;
            height: 40vh;
        }

        .rotate-image {
            left: 48%;
            width: 10vw;
            height: 8vh;
            animation: circle768 10s infinite linear;
        }

        .light-background {
            width: 43vw;

            h3 {
                font-size: 1.1rem;
            }
        }

        .dark-background {
            width: 43vw;

            h3 {
                font-size: 1.1rem;
            }
        }

        .home-interior {
            top: 1%;
        }

        .home-number {
            top: 1%;
        }

        .home-affordable {
            top: 30%;
        }

        .home-consultation {
            top: 50%;
            left: 2%;
        }

        .home-support {
            bottom: 1%;
            top: auto;
            left: 5%;
            right: auto;
        }

        .home-guaranteed {
            bottom: 1%;
            top: auto;
            right: 5%;
            left: auto;
        }
    }

    @keyframes circle768 {
        0% {
            top: 40%;
            left: 48%;
        }

        50% {
            top: 54%;
            left: 48.5%;
        }

        100% {
            top: 40%;
            left: 48%;
        }
    }

    .home-typewriter {

        .home-best {
            font-size: 0.97rem;

            span {
                font-size: 0.97rem;
            }
        }
    }

    .home-footer {
        display: block;
        font-size: 1rem;

        .home-footer-info {
            width: 100%;
            padding: 2% 5%;

            .home-logo-image {
                margin-bottom: 0;
            }
        }

        .home-services {
            width: 100%;
            padding: 2% 5%;

            .home-services-head {
                margin-bottom: 20px;
            }

            ul {
                li {
                    margin-bottom: 2px;
                }
            }
        }

        .home-contact-us {
            width: 100%;
            padding: 1% 5% 2% 5%;

            .home-contact-head {
                margin-bottom: 20px;
            }

            .home-contacts {

                .home-phone {
                    margin-left: 5px;
                }

                .home-email{
                    margin-left: 3px;
                    font-size: 1rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 562px) {
    div#slider {

        figure {

            img {
                height: 70vh;
            }
        }

        .text {
            font-size: 1.6rem;
            width: 80%;
            top: 35%;
        }

        .small-text {
            font-size: 1.2rem;
            width: 80%;
            top: 50%;
        }

        .btn {
            top: 65%;
        }
    }

    .home-card {
        width: 80%;
        margin-left: 10%;
    }

    .home-card-info {
        p {
            margin-top: 22%;
        }
    }

    .display {
        margin-top: 2rem;

        .dis {
            margin-bottom: 2rem;

            .disp {
                width: 85%;

                .display-head {
                    font-size: 1.5rem;
                    margin-bottom: 0.2rem;
                }

                .display-subhead {
                    font-size: 0.9rem;
                }
            }
        }

        .galleries{
            text-align: center;
            margin-left: 0;
        }

        .gallery-1 {
            #living-room {
                width: 75%;
            }
        }

        .gallery-2{

            #master-bedroom{
                width: 75%;
                margin-left: 0;
                margin-top: 8px;
            }

            #master-head{
                left: 1%;
            }
        }

        .gallery-3{

            #balcony{
                width: 75%;
                margin-left: 0;
                margin-top: 8px;
            }

            #balcony-head{
                left: 1%;
            }
        }

        .gallery-4{

            #bathroom{
                width: 75%;
                margin-left: 0;
            }

            #bathroom-head{
                left: 1%;
            }
        }

        .gallery-5{

            #kitchen{
                width: 75%;
                margin-left: 0;
            }

            #kitchen-head{
                left: 1%;
            }
        }

        .gallery-6{

            #wardrobe{
                width: 75%;
                margin-left: 0;
            }

            #wardrobe-head{
                left: 1%;
            }
        }
    }

    .choice-container{
        margin-top: 2rem;
        font-size: 1.6rem;

        .choice-head{
            padding: 10px;
        }

        .home-container{
            padding-top: 85%;
            padding-bottom: 90%;
            text-align: center;
        }

        .circular-image{
            width: 55vw;
            height: 35vh;
        }

        .rotate-image{
            width: 13vw;
            height: 7vh;
            left: 14%;
            top: 45%;
            animation: circle562 10s infinite linear;
        }

        .light-background{
            width: 85vw;

            p{
                font-size: 0.8rem;
            }
        }

        .dark-background{
            width: 85vw;

            p{
                font-size: 0.8rem;
            }
        }

        .home-interior {
            top: 1%;
        }

        .home-number{
            top: 14%;
        }

        .home-consultation {
            top: 26.5%;
            left: 4%;
        }

        .home-affordable {
            top: 63%;
            right: 4%;
            left: auto;
        }

        .home-guaranteed {
            bottom: 15%;
            top: auto;
            left: 4%;
            right: auto;
        }

        .home-support {
            bottom: 2%;
            top: auto;
            right: 4%;
            left: auto;
        }
    }

    @keyframes circle562 {
        0% {
            top: 45%;
            left: 14%;
        }

        50% {
            top: 51%;
            left: 14.5%;
        }

        100% {
            top: 45%;
            left: 14%;
        }
    }

    .home-typewriter{

        .home-best{
            font-size: 0.6rem;

            span{
                font-size: 0.6rem;
                margin-left: 1px;
            }
        }
    }

    .home-footer{

        .home-footer-info{
            padding: 1% 5%;

            .home-logo-image{
                margin-bottom: 0;
            }
        }

        .home-services{
            
            .home-services-head{
                margin-bottom: 20px;
            }
        }

        .home-contact-us{

            .home-contact-head{
                margin-bottom: 20px;
            }

            .home-contacts{
                margin-bottom: 10px;

                .home-phone{
                    margin-left: 0;
                }

                .home-email{
                    margin-left: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 470px){
    .choice-container{
        margin-top: 2rem;
        font-size: 1.6rem;

        .choice-head{
            padding: 10px;
        }

        .home-container{
            padding-top: 110%;
            padding-bottom: 110%;
            text-align: center;
        }

        .circular-image{
            width: 60vw;
            height: 30vh;
        }

        .home-number{
            top: 15%;
        }

        .home-consultation {
            top: 28%;
        }

        .home-affordable {
            top: 62%;
        }

        .home-guaranteed {
            bottom: 13%;
        }

        .home-support {
            bottom: 0%;
        }
    }
}

@media only screen and (max-width: 380px){
    .choice-container{

        .home-container{
            padding-top: 170%;
            padding-bottom: 170%;
        }

        .circular-image{
            width: 70vw;
            height: 30vh;
        }

        .rotate-image{
            width: 14vw;
            height: 6vh;
            left: 8%;
            top: 45%;
            animation: circle380 10s infinite linear;
        }

        .home-consultation {
            top: 30%;
        }

        .home-affordable {
            top: 61%;
        }

        .home-guaranteed {
            bottom: 14%;
        }

        .home-support {
            bottom: 1.5%;
        }
    }

    @keyframes circle380 {
        0% {
            top: 45%;
            left: 8%;
        }

        50% {
            top: 51%;
            left: 8.5%;
        }

        100% {
            top: 45%;
            left: 8%;
        }
    }
}