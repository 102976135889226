@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Roboto+Slab:wght@300;400;500;600;700&display=swap');

.contact-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
    background: url('../../images/Contact/contact-bg.jpeg');
    background-size: 100% 100%;
    
    .consultant-form {
        background-color: white;
        width: 30%;
        padding: 2rem 2.5rem;
        border-radius: 20px;

        .form-head {
            text-align: center;
            padding: 10px;
            margin-bottom: 1.5rem;
            font-size: 1.3rem;

            h3 {
                padding: 5px;
                display: inline;
                border-radius: 5px;
                border-bottom: 3px solid darkblue;
            }
        }

        input {
            width: 100%;
            padding: 10px 15px;
            margin-bottom: 15px;
            border: none;
            border-radius: 10px;
            font-size: 1rem;
            box-shadow: 1px 1px 5px 1px grey;
        }

        .submit {
            margin-top: 15px;
            text-align: center;

            input {
                background-color: #05445E;
                color: whitesmoke;
                font-size: 1.2rem;
                cursor: pointer;

                &:hover {
                    background-color: #085f84;
                }
            }
        }
    }
}

@media only screen and (max-width:1140px){
    .contact-form-container{
        
        .consultant-form{
            width: 51%;
        }
    }
}

@media only screen and (max-width: 768px){
    .contact-form-container{
        padding: 3rem 0;

        .consultant-form{
            width: 70%;
        }
    }
}

@media only screen and (max-width: 562px){
    .contact-form-container{
        padding: 0;

        .consultant-form{
            width: 100%;

            .form-head{
                font-size: 1.2rem;
            }
        }
    }
}