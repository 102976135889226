@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Roboto+Slab:wght@300;400;500;600;700&display=swap");
.services-display {
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  position: relative;
}
.services-display img {
  padding: 0;
  width: 50%;
  height: 65vh;
}
.services-display .display-color {
  position: absolute;
  width: 100%;
  height: 65vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.services-display p {
  position: absolute;
  width: 100%;
  text-align: center;
  font-family: "Playfair Display", sans-serif;
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 700;
  color: whitesmoke;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.type-name {
  padding-bottom: 2.5rem;
}
.type-name .type-head-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  padding-top: 1rem;
}
.type-name .type-head-container .type-container {
  width: 60%;
  padding: 1rem;
  text-align: center;
}
.type-name .type-head-container .type-container .type-head {
  padding: 1.5rem;
  font-size: 2.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 0.4rem;
  color: #0C1446;
  border-top: 1px solid #90ADC6;
  border-bottom: 1px solid #90ADC6;
}
.type-name .type-head-container .type-container .type-subhead {
  color: #1f5a61;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  padding-top: 1rem;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  width: 100%;
  border-radius: 10px;
  margin: 0 15px;
}
.item:hover {
  transform: scale(1.01);
}
.item img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
}

.even-type-div {
  background-color: #ebeaea;
}
.even-type-div .even-type-head-container .even-type-container .even-type-head {
  color: #490909;
  border-top: 1px solid #774949;
  border-bottom: 1px solid #774949;
}
.even-type-div .even-type-head-container .even-type-container .even-type-subhead {
  color: #b36262;
}

.services-typewriter {
  position: relative;
  height: 100px;
  width: 100%;
  background-color: #2F5061;
}
.services-typewriter .services-best {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.9rem;
  font-family: "Red Hat Display";
  font-weight: 700;
  color: #ECFDF1;
  width: 100%;
}
.services-typewriter .services-best span {
  font-size: 1.9rem;
  font-family: "Red Hat Display";
  font-weight: 700;
}

.services-footer {
  background: url(../images/footer.jpg);
  background-size: 100% 100%;
  color: black;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  display: flex;
}
.services-footer .services-footer-info {
  width: 50%;
  padding: 5% 3% 5% 20%;
}
.services-footer .services-footer-info .services-logo-image {
  text-align: center;
  margin-bottom: 20px;
}
.services-footer .services-footer-info .services-logo-image img {
  width: 100px;
}
.services-footer .services-footer-info p {
  padding: 0 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.services-footer .services-services {
  width: 15%;
  padding: 6% 1% 5% 1%;
}
.services-footer .services-services .services-services-head {
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  color: #1b2d4b;
}
.services-footer .services-services ul {
  list-style: none;
}
.services-footer .services-services ul li {
  text-align: center;
  margin-bottom: 5px;
}
.services-footer .services-services ul li a {
  text-decoration: none;
  color: black;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.services-footer .services-services ul li a:hover {
  color: #044662;
}
.services-footer .services-contact-us {
  width: 30%;
  padding: 6% 0% 5% 2%;
}
.services-footer .services-contact-us .services-contact-head {
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  color: #1b2d4b;
}
.services-footer .services-contact-us .services-contacts {
  display: flex;
  margin-bottom: 20px;
  margin-left: 20px;
}
.services-footer .services-contact-us .services-contacts .services-address {
  width: 50px;
  margin-right: 15px;
  margin-top: 5px;
}
.services-footer .services-contact-us .services-contacts .services-phone {
  margin-right: 15px;
  margin-top: 5px;
}
.services-footer .services-contact-us .services-contacts .services-email {
  font-size: 1.3rem;
  margin-right: 15px;
  margin-top: 5px;
}
.services-footer .services-contact-us .services-contacts p {
  font-size: 1.1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.rec.rec-arrow-left {
  margin-left: 15px;
}

.rec.rec-arrow-right {
  margin-right: 15px;
}

@media only screen and (max-width: 1140px) {
  .services-display img {
    height: 50vh;
  }
  .services-display .display-color {
    height: 50vh;
  }
  .services-display p {
    font-size: 2rem;
  }
  .type-name {
    padding-bottom: 2rem;
  }
  .type-name .type-head-container {
    margin-bottom: 1.5rem;
  }
  .type-name .type-head-container .type-container {
    width: 80%;
  }
  .type-name .type-head-container .type-container .type-head {
    font-size: 2.2rem;
    padding: 1rem;
  }
  .item {
    height: 35vh;
  }
  .item:hover {
    transform: scale(1.01);
  }
  .services-typewriter {
    height: auto;
  }
  .services-typewriter .services-best {
    padding: 20px 0;
    font-size: 1.3rem;
  }
  .services-typewriter .services-best span {
    font-size: 1.3rem;
  }
  .services-footer {
    font-size: 1.1rem;
  }
  .services-footer .services-footer-info {
    width: 40%;
    padding: 5% 1%;
  }
  .services-footer .services-footer-info .services-logo-image {
    margin-bottom: 10px;
  }
  .services-footer .services-footer-info p {
    padding: 0 5px;
  }
  .services-footer .services-services {
    width: 30%;
    padding: 8% 1%;
  }
  .services-footer .services-services .services-services-head {
    font-size: 1.5rem;
  }
  .services-footer .services-contact-us {
    width: 30%;
    padding: 8% 1% 8% 0%;
  }
  .services-footer .services-contact-us .services-contact-head {
    font-size: 1.5rem;
  }
  .services-footer .services-contact-us .services-contacts {
    margin-left: 5px;
  }
  .services-footer .services-contact-us .services-contacts .services-email {
    font-size: 1.2rem;
  }
  .services-footer .services-contact-us .services-contacts p {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 768px) {
  .services-display img {
    height: 40vh;
  }
  .services-display .display-color {
    height: 40vh;
  }
  .services-display p {
    font-size: 1.7rem;
  }
  .type-name .type-head-container {
    margin-bottom: 1.1rem;
  }
  .type-name .type-head-container .type-container {
    width: 85%;
  }
  .type-name .type-head-container .type-container .type-head {
    font-size: 2rem;
  }
  .type-name .type-head-container .type-container .type-subhead {
    font-size: 1.1rem;
  }
  .item {
    margin: 0 5px;
  }
  .services-typewriter .services-best {
    font-size: 0.97rem;
  }
  .services-typewriter .services-best span {
    font-size: 0.97rem;
  }
  .services-footer {
    display: block;
    font-size: 1rem;
  }
  .services-footer .services-footer-info {
    width: 100%;
    padding: 2% 5%;
  }
  .services-footer .services-footer-info .services-logo-image {
    margin-bottom: 0;
  }
  .services-footer .services-services {
    width: 100%;
    padding: 2% 5%;
  }
  .services-footer .services-services .services-services-head {
    margin-bottom: 20px;
  }
  .services-footer .services-services ul li {
    margin-bottom: 2px;
  }
  .services-footer .services-contact-us {
    width: 100%;
    padding: 1% 5% 2% 5%;
  }
  .services-footer .services-contact-us .services-contact-head {
    margin-bottom: 20px;
  }
  .services-footer .services-contact-us .services-contacts .services-phone {
    margin-left: 5px;
  }
  .services-footer .services-contact-us .services-contacts .services-email {
    margin-left: 3px;
    font-size: 1rem;
  }
  .rec.rec-arrow-left {
    margin-left: 5px;
  }
  .rec.rec-arrow-right {
    margin-right: 5px;
  }
}
@media only screen and (max-width: 562px) {
  .services-display img {
    height: 30vh;
  }
  .services-display .display-color {
    height: 30vh;
  }
  .services-display p {
    font-size: 1.4rem;
  }
  .type-name {
    padding-bottom: 1.5rem;
  }
  .type-name .type-head-container {
    margin-bottom: 0.5rem;
  }
  .type-name .type-head-container .type-container {
    width: 95%;
  }
  .type-name .type-head-container .type-container .type-head {
    font-size: 1.5rem;
  }
  .type-name .type-head-container .type-container .type-subhead {
    font-size: 0.8rem;
  }
  .item {
    height: 25vh;
    margin: 0;
  }
  .services-typewriter .services-best {
    font-size: 0.6rem;
  }
  .services-typewriter .services-best span {
    font-size: 0.6rem;
    margin-left: 1px;
  }
  .services-footer {
    font-size: 0.9rem;
  }
  .services-footer .services-footer-info {
    padding: 1% 5%;
  }
  .services-footer .services-footer-info .services-logo-image {
    margin-bottom: 0;
  }
  .services-footer .services-services .services-services-head {
    margin-bottom: 20px;
  }
  .services-footer .services-contact-us .services-contact-head {
    margin-bottom: 20px;
  }
  .services-footer .services-contact-us .services-contacts {
    margin-bottom: 10px;
  }
  .services-footer .services-contact-us .services-contacts .services-phone {
    margin-left: 0;
  }
  .services-footer .services-contact-us .services-contacts .services-email {
    margin-left: 0;
  }
  .services-footer .services-contact-us .services-contacts p {
    font-size: 0.9rem;
  }
}/*# sourceMappingURL=Services.css.map */