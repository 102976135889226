@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

.gallery-container {
    height: 100vh;
    margin: 0;

    .display {
        margin: 0;
        height: 90vh;
        position: relative;
        background-color: #D4F1F4;

        .vase-1 {
            position: absolute;
            bottom: -3%;
            left: 28%;

            img {
                width: 27%;
            }
        }

        .vase-2 {
            position: absolute;
            bottom: -3%;
            left: 62%;

            img {
                width: 27%;
            }
        }

        .gallery-container-info {
            position: absolute;
            font-size: 3rem;
            width: 20%;
            top: 38%;
            left: 8%;
            letter-spacing: 0.05rem;
            word-spacing: 0.1rem;
            font-weight: 700;
            font-family: 'Raleway', sans-serif;
            color: #0C2D48;
        }

        .img-1 {
            position: absolute;
            border-radius: 50%;
            border: 6px solid #90ADC6;

            img {
                padding: 10px;
                width: 14vw;
            }
        }

        .img-4 {
            position: absolute;
            border-radius: 50%;
            border: 6px solid #90ADC6;
            left: 35%;
            top: 10%;

            img {
                padding: 17px;
                width: 10vw;
            }
        }

        .img-3 {
            position: absolute;
            border-radius: 50%;
            border: 6px solid #90ADC6;
            left: 15%;
            top: 70%;

            img {
                padding: 20px;
                width: 10vw;
            }
        }

        .img-2 {
            position: absolute;
            border-radius: 50%;
            border: 6px solid #90ADC6;
            left: 63%;
            top: 10%;

            img {
                padding: 20px;
                width: 14vw;
            }
        }

        .outerRect {
            border: 20px solid #90ADC6;
            position: absolute;
            width: 35vw;
            height: 67vh;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0%);
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;

            .innerRect {
                position: absolute;
                width: 32.5vw;
                height: auto;
                bottom: -52%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-top-left-radius: 50%;
                border-top-right-radius: 50%;
                overflow: hidden;

                figure {
                    position: relative;
                    width: 500%;
                    margin: 0;
                    left: 0;
                    animation: 10s slidy infinite;

                    img {
                        width: 32.5vw;
                        height: 62.5vh;
                    }
                }
            }
        }


        .consultant-form {
            position: absolute;
            top: 30%;
            right: 1.5%;
            background-color: white;
            width: 27%;
            padding: 30px;
            border-radius: 20px;

            .form-head {
                text-align: center;
                padding: 10px;
                margin-bottom: 20px;
                font-size: 1.3rem;

                h3 {
                    padding: 5px;
                    display: inline;
                    border-radius: 5px;
                    border-bottom: 3px solid darkblue;
                }
            }

            input {
                width: 100%;
                padding: 10px 15px;
                margin-bottom: 15px;
                border: none;
                border-radius: 10px;
                font-size: 1rem;
                box-shadow: 1px 1px 5px 1px grey;
            }

            .submit {
                margin-top: 15px;
                text-align: center;

                input {
                    background-color: #05445E;
                    color: whitesmoke;
                    font-size: 1.2rem;
                    cursor: pointer;

                    &:hover {
                        background-color: #085f84;
                    }
                }
            }
        }
    }
}

@keyframes slidy {
    0% {
        left: 0%;
    }

    20% {
        left: 0%;
    }

    25% {
        left: -100%;
    }

    45% {
        left: -100%;
    }

    50% {
        left: -200%;
    }

    70% {
        left: -200%;
    }

    75% {
        left: -300%;
    }

    95% {
        left: -300%;
    }

    100% {
        left: -400%;
    }
}

.gallery-pics-container {
    padding: 60px;
    background: url(../../images/Gallery/gallery-6.jpg);
    background-size: 100% 100%;

    .gallery-head {
        margin-bottom: 30px;

        .gall-head {
            font-size: 2.5rem;
            padding-bottom: 20px;
            font-weight: 600;
            font-family: 'Poppins', sans-serif;
            text-align: center;
            color: #0C2D48;
        }

        .gallery-subhead {
            font-size: 1.2rem;
            font-weight: 400;
            font-style: italic;
            font-family: 'Poppins', sans-serif;
            text-align: center;
            color: #104679;
        }
    }

    .galleries {
        margin-left: 4rem;
        padding-bottom: 2rem;
        text-align: center;
    }

    .gallery-1 {
        position: relative;
        display: inline;
        cursor: pointer;

        #living-room {
            width: 30%;
            border-radius: 10px;
            height: 40vh;

            &:hover {
                transform: scale(1.05);
            }
        }

        #living-head {
            position: absolute;
            bottom: 40%;
            left: 1%;
            font-size: 1.1rem;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border: 0.2px solid rgba(55, 54, 54, 0.5);
            padding: 2px 10px;
            border-radius: 10px;
            background-color: rgba(55, 54, 54, 0.7);
            color: whitesmoke;
        }
    }

    .gallery-2 {
        position: relative;
        display: inline;
        cursor: pointer;

        #kitchen {
            width: 30%;
            height: 40vh;
            border-radius: 10px;
            margin-left: 20px;

            &:hover {
                transform: scale(1.05);
            }
        }

        #kitchen-head {
            position: absolute;
            bottom: 30%;
            left: 6%;
            font-size: 1.1rem;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border: 0.2px solid rgba(55, 54, 54, 0.5);
            padding: 2px 10px;
            border-radius: 10px;
            background-color: rgba(55, 54, 54, 0.7);
            color: whitesmoke;
        }
    }

    .gallery-3 {
        position: relative;
        display: inline;
        cursor: pointer;

        #master {
            width: 30%;
            height: 40vh;
            border-radius: 10px;
            margin-left: 20px;

            &:hover {
                transform: scale(1.05);
            }
        }

        #master-head {
            position: absolute;
            bottom: 30%;
            left: 6%;
            font-size: 1.1rem;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border: 0.2px solid rgba(55, 54, 54, 0.5);
            padding: 2px 10px;
            border-radius: 10px;
            background-color: rgba(55, 54, 54, 0.7);
            color: whitesmoke;
        }
    }

    .gallery-4 {
        position: relative;
        display: inline;
        cursor: pointer;

        #bathroom {
            width: 30%;
            height: 40vh;
            border-radius: 10px;
            margin-top: 20px;

            &:hover {
                transform: scale(1.05);
            }
        }

        #bath-head {
            position: absolute;
            bottom: 30%;
            left: 2%;
            font-size: 1.1rem;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border: 0.2px solid rgba(55, 54, 54, 0.5);
            padding: 2px 10px;
            border-radius: 10px;
            background-color: rgba(55, 54, 54, 0.7);
            color: whitesmoke;
        }
    }

    .gallery-5 {
        position: relative;
        display: inline;
        cursor: pointer;

        #wardrobe {
            width: 30%;
            height: 40vh;
            border-radius: 10px;
            margin-left: 20px;
            margin-top: 20px;

            &:hover {
                transform: scale(1.05);
            }
        }

        #wardrobe-head {
            position: absolute;
            bottom: 40%;
            left: 6%;
            font-size: 1.1rem;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border: 0.2px solid rgba(55, 54, 54, 0.5);
            padding: 2px 10px;
            border-radius: 10px;
            background-color: rgba(55, 54, 54, 0.7);
            color: whitesmoke;
        }
    }

    .gallery-6 {
        position: relative;
        display: inline;
        cursor: pointer;

        #gallery-balcony {
            width: 30%;
            height: 40vh;
            border-radius: 10px;
            margin-left: 20px;
            margin-top: 20px;

            &:hover {
                transform: scale(1.05);
            }
        }

        #balcony-head {
            position: absolute;
            bottom: 40%;
            left: 6%;
            font-size: 1.1rem;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            border: 0.2px solid rgba(55, 54, 54, 0.5);
            padding: 2px 10px;
            border-radius: 10px;
            background-color: rgba(55, 54, 54, 0.7);
            color: whitesmoke;
        }
    }
}

.gallery-consultation-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../images/Gallery/gallery-5.jpg);
    background-size: 100% 100%;

    .gallery-consultation {
        width: 45%;
        padding: 70px;

        .consultation-head {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 3rem;
            font-weight: 700;
            font-family: 'Poppins', sans-serif;
            margin-bottom: 8%;
            color: #4F0000;
        }

        .consultation {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 2rem;
            font-weight: 600;
            font-family: 'Poppins', sans-serif;
            margin-bottom: 5%;
            color: #735055;
        }

        #consultation-1 {
            position: absolute;
            top: 45%;
            left: 22%;
            border-radius: 50%;
            border: 3px solid #a36f76;

            img {
                width: 7vw;
                padding: 15px;
            }
        }

        #consultation-2 {
            position: absolute;
            top: 65%;
            left: 22%;
            border-radius: 50%;
            border: 3px solid #a36f76;

            img {
                width: 7vw;
                padding: 15px;
            }
        }

        .consultation-btn {
            text-align: center;

            button {
                padding: 10px;
                font-size: 1.2rem;
                border-radius: 10px;
                border: none;
                outline: none;
                background-color: #a36f76;
                color: #D9CED6;
                cursor: pointer;
                font-weight: 400;
                font-family: 'Raleway', sans-serif;
                margin-top: 10px;

                &:hover {
                    background-color: #d8959e;
                    transform: scale(1.02);
                }
            }
        }
    }
}

.gallery-steps-container {

    .gallery-steps-head-container {
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        .gallery-steps-head {
            font-size: 2.5rem;
            text-align: center;
            padding: 20px;
            color: #143f64;
            letter-spacing: 0.05rem;
            word-spacing: 0.1rem;
            font-weight: 700;
            font-family: 'Raleway', sans-serif;
        }
    }

    .gallery-steps {
        padding: 20px;
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 20px;

        .steps {
            width: 100%;

            .steps-image {
                text-align: center;
                padding: 20px;
                width: 100%;
                height: 80%;

                img {
                    width: 50%;
                    height: auto;
                }
            }

            .steps-text {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2rem;
                color: #145DA0;
                font-weight: 600;
                font-family: 'Poppins', sans-serif;

                .numbers {
                    width: 50px;
                    padding-left: 10px;
                }

                p {
                    width: 80%;
                    padding-right: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1140px) {
    .gallery-container {

        .display {

            .vase-1 {
                left: 19%;

                img {
                    width: 24%;
                }
            }

            .vase-2 {
                left: 64%;

                img {
                    width: 29%;
                }
            }

            .img-3 {
                left: 5%;

                img {
                    width: 12vw;
                }
            }

            .img-4 {
                top: 10%;
                left: 30%;

                img {
                    width: 12vw;
                }
            }

            .gallery-container-info {
                font-size: 2.5rem;
                left: 4%;
                width: 25%;
            }

            .outerRect {
                width: 44vw;
                left: 47%;

                .innerRect {
                    width: 41vw;

                    figure {

                        img {
                            width: 41vw;
                        }
                    }
                }
            }

            .consultant-form {
                width: 29%;
                right: 1%;
                padding: 25px;

                input {
                    padding: 10px;
                    font-size: 0.9rem;
                }
            }
        }
    }

    .gallery-pics-container {
        padding: 5% 7%;

        .gallery-head {

            .gall-head {
                padding-bottom: 15px;
                font-size: 2.2rem;
            }

            .gallery-subhead {
                font-size: 1.1rem;
            }
        }

        .galleries {
            margin-left: 0;
            text-align: center;
        }

        .gallery-1 {

            #living-room {
                height: 35vh;
                width: 32%;
            }
        }

        .gallery-2 {

            #kitchen {
                height: 35vh;
                width: 32%;
                margin-left: 15px;
            }
        }

        .gallery-3 {

            #master {
                height: 35vh;
                width: 32%;
                margin-left: 15px;
            }
        }

        .gallery-4 {

            #bathroom {
                height: 35vh;
                width: 32%;
                margin-top: 15px;
            }
        }

        .gallery-5 {

            #wardrobe {
                height: 35vh;
                width: 32%;
                margin-left: 15px;
                margin-top: 15px;
            }
        }

        .gallery-6 {

            #gallery-balcony {
                height: 35vh;
                width: 32%;
                margin-left: 15px;
                margin-top: 15px;
            }
        }
    }

    .gallery-consultation-container {

        .gallery-consultation {
            width: 60%;

            .consultation-head {
                font-size: 2.2rem;
            }

            .consultation {
                font-size: 1.5rem;
            }

            #consultation-1 {
                top: 46%;
                left: 17%;

                img {
                    padding: 8px;
                }
            }

            #consultation-2 {
                left: 17%;

                img {
                    padding: 5px;
                }
            }
        }
    }

    .gallery-steps-container {

        .gallery-steps-head-container {

            .gallery-steps-head {
                font-size: 2rem;
            }
        }

        .gallery-steps {

            .steps {

                .steps-image {
                    height: 60%;
                }

                .steps-text {
                    font-size: 1.1rem;

                    p {
                        width: 90%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width : 870px) {
    .gallery-container {
        height: 90vh;

        .display {
            height: 80vh;

            .vase-1 {
                left: 21%;

                img {
                    width: 19%;
                }
            }

            .vase-2 {
                left: 62%;
            }

            .gallery-container-info {
                font-size: 2rem;
                width: 28%;
                left: 3%;
            }

            .img-4 {
                top: 24%;

                img {
                    width: 13vw;
                }
            }

            .img-3 {

                img {
                    width: 13vw;
                }
            }

            .img-2 {
                top: 15%;
                left: 60%;

                img {
                    width: 15vw;
                }
            }

            .outerRect {
                width: 40vw;
                height: 50vh;

                .innerRect {
                    width: 37vw;
                    bottom: -50%;
                    height: 45vh;

                    figure {
                        img {
                            width: 37vw;
                            height: 45vh;
                        }
                    }
                }
            }

            .consultant-form {
                width: 31%;
                padding: 20px;

                .form-head {
                    margin-bottom: 10px;
                    font-size: 1.2rem;
                }

                input {
                    margin-bottom: 10px;
                }

                .submit {
                    margin-top: 10px;

                    input {
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }

    .gallery-pics-container {

        .gallery-1 {

            #living-room {
                width: 45%;
            }
        }

        .gallery-2 {

            #kitchen {
                height: 35vh;
                width: 45%;
                margin-left: 15px;
            }
        }

        .gallery-3 {

            #master {
                width: 45%;
                margin-top: 15px;
                margin-left: 0;
            }

            #master-head {
                left: 2%;
            }
        }

        .gallery-4 {

            #bathroom {
                width: 45%;
                margin-left: 15px;
            }

            #bath-head {
                left: 6%;
            }
        }

        .gallery-5 {

            #wardrobe {
                width: 45%;
                margin-left: 0;
            }

            #wardrobe-head {
                left: 2%;
            }
        }

        .gallery-6 {

            #gallery-balcony {
                width: 45%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .gallery-container {
        height: 80vh;

        .display {
            height: 70vh;

            .vase-2 {
                left: 59%;
            }

            .vase-1 {
                left: 18%;
            }

            .img-4 {
                top: 22%;
            }

            .img-2 {
                top: 10%;
            }

            .img-3 {
                left: 4%;
            }

            .gallery-container-info {
                font-size: 1.6rem;
                width: 30%;
                top: 35%;
            }

            .outerRect {
                left: 45%;
                height: 45vh;

                .innerRect {
                    height: 40vh;
                }
            }

            .consultant-form {
                top: 25%;

                .form-head {
                    padding: 5px;
                    font-size: 1rem;
                    margin-bottom: 15px;
                }

                input {
                    font-size: 0.8rem;
                    padding: 8px;
                }
            }
        }
    }

    .gallery-pics-container{
        padding: 3% 5%;
    }

    .gallery-consultation-container {

        .gallery-consultation{
            width: 70%;

            .consultation-head{
                font-size: 1.8rem;
            }

            .consultation{
                font-size: 1.2rem;
            }

            #consultation-1{
                left: 15%;
                top: 47%;
            }

            #consultation-2{
                left: 15%;
                top: 64%;
            }
        }
    }

    .gallery-steps-container{

        .gallery-steps-head-container{
            .gallery-steps-head{
                font-size: 1.6rem;
            }
        }

        .gallery-steps{

            .steps{
                .steps-image{
                    height: 50%;
                }

                .steps-text {
                    font-size: 1rem;

                    p {
                        width: 120%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 562px){
    .gallery-container{
        width: 100%;
        height: 110vh;
        background-color: #ebeaea;
        
        .display{
            height: 50vh;

            .gallery-container-info{
                font-size: 1.2rem;
                width: 35%;
            }

            .vase-1{
                left: 31%;

                img{
                    width: 15%;
                }
            }

            .vase-2{
                left: 80%;

                img{
                    width: 50%;
                }
            }

            .img-4{
                top: 15%;
                left: 35%;
                border: 3px solid #90ADC6;

                img{
                    padding: 10px;
                    width: 16vw;
                }
            }
            .img-1{
                border: 3px solid #90ADC6;

                img{
                    padding: 10px;
                    width: 18vw;
                }
            }
            .img-3{
                border: 3px solid #90ADC6;
                left: 10%;
                top: 80%;

                img{
                    padding: 10px;
                    width: 14vw;
                }
            }
            .img-2{
                border: 3px solid #90ADC6;
                top: 22%;
                left: 80%;

                img{
                    padding: 10px;
                    width: 16vw;
                }
            }

            .outerRect{
                border: 15px solid #90ADC6;
                height: 35vh;
                width: 50vw;
                left: 60%;

                .innerRect{
                    height: 32vh;
                    width: 45vw;
                    bottom: -52%;

                    figure{

                        img{
                            width: 45vw;
                            height: 32vh;
                        }
                    }
                }
            }

            .consultant-form{
                top: 110%;
                right: 22%;
                width: 55%;
                border-radius: 10px;

                .form-head{
                    font-size: 1rem;
                    padding: 5px;
                }

                input{
                    border-radius: 5px;
                }
            }
        }
    }

    .gallery-pics-container{
        padding: 2%;
        width: 100%;

        .gallery-head{
            margin-bottom: 20px;
            padding-top: 5%;

            .gall-head{
                font-size: 1.5rem;
            }

            .gallery-subhead{
                font-size: 0.8rem;
            }
        }

        .gallery-1 {

            #living-room {
                width: 80%;
            }
        }

        .gallery-2 {

            #kitchen {
                width: 80%;
                margin-left: 0;
                margin-top: 15px;
            }

            #kitchen-head{
                left: 2%;
            }
        }

        .gallery-3 {

            #master {
                width: 80%;
                margin-left: 0;
            }
        }

        .gallery-4 {

            #bathroom {
                width: 80%;
                margin-top: 15px;
                margin-left: 0;
            }

            #bath-head{
                left: 2%;
            }
        }

        .gallery-5 {

            #wardrobe {
                width: 80%;
                margin-left: 0;
                margin-top: 15px;
            }
        }

        .gallery-6 {

            #gallery-balcony {
                width: 80%;
                margin-left: 0;
                margin-top: 15px;
            }

            #balcony-head{
                left: 2%;
            }
        }
    }

    .gallery-consultation-container{

        .gallery-consultation{
            width: 90%;

            .consultation-head{
                font-size: 1.2rem;
            }

            .consultation{
                font-size: 0.8rem;
            }

            #consultation-1{
                left: 11%;
                top: 45%;
                border: 2px solid #a36f76;

                img{
                    padding: 5px;
                }
            }

            #consultation-2{
                left: 11%;
                top: 61%;
                border: 2px solid #a36f76;

                img{
                    padding: 5px;
                }
            }

            .consultation-btn{

                button{
                    font-size: 0.9rem;
                    padding: 8px;
                    border-radius: 5px;
                }
            }
        }
    }

    .gallery-steps-container{
        
        .gallery-steps-head-container{
            .gallery-steps-head{
                font-size: 1.4rem;
            }
        }

        .gallery-steps{
            grid-template-columns: auto auto;

            .steps{

                .steps-image{
                    height: 60%;

                    img{
                        width: 70%;
                    }
                }

                .steps-text {
                    font-size: 0.8rem;

                    p {
                        width: 130%;
                    }
                }
            }
        }
    }
}